import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Button,
  Switch,
  Autocomplete,
} from "@mui/material";

import Sidebar from "../../components/Sidebar";
import {
  Main,
  SearchWrapper,
  SubTitle,
  Table,
  Tables,
  Title,
} from "./quicksearch";
import {
  Content,
  DownloadExcelWrapper,
} from "../../utils/stylesbase";

import StoreContext from "../../components/Store/Context";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { API_URL } from "../../utils/env";

const convertDate = (date) => {
  date = date.split("/");
  const day = date[0];
  const month = date[(1, 5)];
  const year = date[2];

  return new Date(`${year}-${month}-${day}`);
};

const convertDateBRtoEN = (date) => {
  date = date.split("/");
  const day = date[0];
  const month = date[(1, 5)];
  const year = date[2];

  return `${year}-${month}-${day}`;
};

const QuickSearch = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const {
    sidebarClosed,
    userName,
    userPermissions,
    setSidebarClosed,
  } = useContext(StoreContext);

  const [reset, setReset] = useState(0);
  const [refSearch, setRefSearch] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [refOptions, setRefOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [tickets, setTickets] = useState([]);

  const tableRef = useRef(null);

  const columnsNames = [
    {
      code: "orderRef",
      name: "#",
    },
    {
      code: "orderPlataform",
      name: "Plataform",
    },
    {
      code: "name",
      name: "Name",
    },
    {
      code: "date",
      name: "Tickets Date",
    },
    {
      code: "sector",
      name: "Tickets",
    },
    {
      code: "pax",
      name: "Pax",
    },
    {
      code: "contact",
      name: "Contact",
    },
    {
      code: "whatsapp",
      name: "Whatsapp",
    },
    {
      code: "fulfilled",
      name: "Fulfilled",
    }
  ];

  const onchange = useCallback((e) => {
    if(e.target.name === "ref"){
      if(e.target && e.target.value && e.target.value.length >= 3){
        setRefSearch(e.target.value);
        fetch(`${API_URL}tickets/quick-search.php?ref=${e.target.value}&name=${nameSearch}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((response) => {
          setRefOptions([...new Set(response.data.map(order => order.orderRef))]);
          if(e.target.finish) setTickets(response.data);
          });
      } else {
        setRefSearch("");
        setRefOptions([]);
        setTickets([]);
      }
    } else if(e.target.name === "name"){
      if(e.target && e.target.value && e.target.value.length >= 3){
        setNameSearch(e.target.value);
        fetch(`${API_URL}tickets/quick-search.php?name=${e.target.value}&ref=${refSearch}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((response) => {
          setNameOptions([...new Set(response.data.map(order => order.name))]);
          if(e.target.finish) setTickets(response.data);
          });
      } else {
        setNameSearch("");
        setNameOptions([]);
        setTickets([]);
      }
    }
  },[refSearch, nameSearch]);



  useEffect(() => {
    setSidebarClosed(true);
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <SubTitle>Quick</SubTitle>
        <Title>Search</Title>
        <SearchWrapper>
          <Autocomplete
            value={refSearch}
            options={refOptions}
            sx={{ width: 300 }}
            onChange={(e, newValue) =>
              onchange({
                target: {
                  name: "ref",
                  finish: true,
                  value: newValue,
                },
              })
            }
            ListboxProps={{
              onClick: (event) => {
                const clickedOption = refOptions.find(
                  (option) => option === event.target.innerText
                );
                if (clickedOption) {
                  onchange({
                    target: {
                      name: "ref",
                      finish: true,
                      value: clickedOption,
                    },
                  })
                }
              },
            }}
            renderInput={(params) => <TextField {...params} label="Order Ref" name="ref" onChange={onchange}/>}
          />
          <Autocomplete
            value={nameSearch}
            options={nameOptions}
            sx={{ width: 300 }}
            onChange={(e, newValue) =>
              onchange({
                target: {
                  name: "name",
                  finish: true,
                  value: newValue,
                },
              })
            }
            ListboxProps={{
              onClick: (event) => {
                const clickedOption = nameOptions.find(
                  (option) => option === event.target.innerText
                );
                if (clickedOption) {
                  onchange({
                    target: {
                      name: "name",
                      finish: true,
                      value: clickedOption,
                    },
                  })
                }
              },
            }}
            renderInput={(params) => <TextField {...params} label="Customer Name" name="name" onChange={onchange}/>}
          />
        </SearchWrapper>
        <DownloadExcelWrapper>
          <DownloadTableExcel
            filename="Order-List"
            sheet="order-list"
            currentTableRef={tableRef.current}
          >
            <Button variant="outlined" style={{ marginBottom: "20px" }}>
              Export to excel
            </Button>
          </DownloadTableExcel>
        </DownloadExcelWrapper>
        <Tables>
          <Table ref={tableRef}>
            <thead>
              <tr>
                {columnsNames.map((column) => {
                  return (
                    <th
                      key={column.code}
                    >
                      {column.name}
                    </th>
                  );
                })}
                <th className="sticky-right"></th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <tr
                    key={ticket.ticket_id}
                    
                  >
                    <td>{ticket.orderRef}</td>
                    <td>{ticket.orderPlataform}</td>
                    <td>{ticket.name}</td>
                    <td>{ticket.date}</td>
                    <td>{ticket.sector}</td>
                   
                    <td>{ticket.pax}</td>
                    <td>{ticket.contact}</td>
                    <td>{ticket.whatsapp}</td>
                    <td>
                      {ticket.fulfilled === "1" ? (
                        <CheckBoxIcon value="yes" />
                      ) : (
                        <CheckBoxOutlineBlankIcon value="no" />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tables>
      </Content>
    </Main>
  );
};

export default QuickSearch;
