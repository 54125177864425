import React, { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../../components/Sidebar";
import Textarea from "@mui/joy/Textarea";

import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  FormButtons,
} from "./transferinput";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const getMonth = (date) => {
  var month = date.getMonth() + 1;
  return month < 10 ? "0" + month : "" + month;
};

const getDay = (date) => {
  var day = date.getDate();
  return day < 10 ? "0" + day : "" + day;
};

const TransferInput = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [dates, setDates] = useState([]);
  const [plataforms, setPlataforms] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [ticketsNames, setTicketsNames] = useState([]);
  const [transfers, setTransfers] = useState([]);

  const { userName, userPermissions } = useContext(StoreContext);

  const curDate = new Date();
  const formatedCurDate = `${curDate.getFullYear()}-${getMonth(
    curDate
  )}-${getDay(curDate)}`;

  const [formData, setFormData] = useState({
    orderRef: "",
    plataform: "",
    name: "",
    pax: "",
    ticketsDate: "",
    contact: "",
    country: "",
    sector: "",
    pickupPoint: "",
    currency: "",
    price: "",
    comments: "",
    transferType: "",
    dateOfRegistration: formatedCurDate,
  });
  const [formsStatus, setFormsStatus] = useState({});

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] = e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;

      let body = formData;
      body.createdBy = userName;
      body.lastEditBy = userName;

      fetch(`${API_URL}transfers/create.php`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>Your transfer has been registered</i>,
              icon: "success",
            }).then(() => {
              return e.nativeEvent.submitter.getAttribute("create-new")
                ? navigate(0)
                : navigate("/transfer-list");
            });
          }
        });
    },
    [formData, userName, userPermissions]
  );

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a, b) => {
          const dateA = a.value.split('/').reverse().join('-');
          const dateB = b.value.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        }).forEach((data) => transformedData.push(data.value));
        setDates(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/plataforms.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setPlataforms(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/currencies.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setCurrencies(transformedData);
      });

    fetch(`${API_URL}dropdown-data/transfers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTransfers(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTicketsNames(transformedData);
      });
  }, []);

  return (
    <Main>
      <Sidebar></Sidebar>
      <Content>
        <SubTitle>Transfer</SubTitle>
        <Title>Input</Title>
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          status={formsStatus}
        >
          <FormRow>
            <TextField
              id="order-ref"
              label="#"
              variant="outlined"
              name="orderRef"
              onChange={onchange}
              value={formData.orderRef}
            />
            <FormControl fullWidth>
              <InputLabel id="order-plataform-label">Plataform</InputLabel>
              <Select
                labelId="order-plataform-label"
                id="plataform"
                name="plataform"
                label="Plataform"
                value={formData.plataform}
                onChange={onchange}
              >
                {plataforms.map((plataform) => {
                  return (
                    <MenuItem value={plataform} key={plataform}>
                      {plataform}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              name="name"
              onChange={onchange}
              value={formData.name}
            />
            <TextField
              id="contact"
              label="Email/whatsapp"
              variant="outlined"
              name="contact"
              onChange={onchange}
              value={formData.contact}
            />
          </FormRow>
          <FormRow>
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              name="country"
              onChange={onchange}
              value={formData.country}
            />
            <FormControl fullWidth>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                label="Currency"
                name="currency"
                onChange={onchange}
                value={formData.currency}
              >
                {currencies.map((currency) => {
                  return (
                    <MenuItem value={currency} key={currency}>
                      {currency}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="pax"
              label="Pax"
              variant="outlined"
              name="pax"
              onChange={onchange}
              value={formData.pax}
              type="number"
            />
            <FormControl fullWidth>
              <InputLabel id="tickets-date-label">Tickets Date</InputLabel>
              <Select
                labelId="tickets-date-label"
                id="ticketsDate"
                label="Tickets Date"
                name="ticketsDate"
                onChange={onchange}
                value={formData.ticketsDate}
              >
                {dates.map((date) => {
                  return (
                    <MenuItem value={date} key={date}>
                      {date}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="tickets-label">Sector</InputLabel>
              <Select
                labelId="tickets-label"
                id="sector"
                label="Sector"
                name="sector"
                onChange={onchange}
                value={formData.sector}
              >
                {ticketsNames.map((sector) => {
                  return (
                    <MenuItem value={sector} key={sector}>
                      {sector}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="pickupPoint"
              label="Pickup Point"
              variant="outlined"
              name="pickupPoint"
              onChange={onchange}
              value={formData.pickupPoint}
            />
          </FormRow>
          <FormRow>
            <TextField
              id="price"
              label="Price"
              variant="outlined"
              name="price"
              onChange={onchange}
              value={formData.price}
              type="number"
            />
            <TextField
              id="dateOfRegistration"
              label="Date of Registration"
              variant="outlined"
              name="dateOfRegistration"
              onChange={onchange}
              value={formData.dateOfRegistration}
              type="date"
            />
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <Autocomplete
                id="transferType"
                options={transfers}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={formData.transferType}
                onChange={(e, newValue) =>
                  onchange({
                    target: {
                      name: "transferType",
                      type: "select",
                      value: newValue,
                    },
                  })
                }
                renderValue={(selected) => selected.join(", ")}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Transfer Type" placeholder="" />
                )}
              />
            </FormControl>
            <Textarea
              minRows={2}
              placeholder="Comments"
              id="comments"
              name="comments"
              onChange={onchange}
              value={formData.comments}
            />
          </FormRow>

          <FormButtons>
            <Button variant="outlined" type="submit">
              Save
            </Button>
            <Button variant="outlined" type="submit" create-new="true">
              Save and create another
            </Button>
          </FormButtons>
        </FormBox>
      </Content>
    </Main>
  );
};

export default TransferInput;
