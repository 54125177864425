import React, { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../../components/Sidebar";

import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  TicketsContainer,
  TicketsRow,
  ComissionModal,
  ComissionModalHeader,
  ComissionModalTitle,
  ComissionModalClose,
  FormButtons,
} from "./oderinput";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const getMonth = (date) => {
  var month = date.getMonth() + 1;
  return month < 10 ? "0" + month : "" + month;
};

const getDay = (date) => {
  var day = date.getDate();
  return day < 10 ? "0" + day : "" + day;
};

const OrderInput = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [dates, setDates] = useState([]);
  const [ticketsNames, setTicketsNames] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [plataforms, setPlataforms] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [comissionModalOpened, setComissionModalOpened] = useState(false);

  const { userName, userPermissions } = useContext(StoreContext);

  const curDate = new Date();
  const formatedCurDate = `${curDate.getFullYear()}-${getMonth(
    curDate
  )}-${getDay(curDate)}`;

  const [formData, setFormData] = useState({
    orderRef: "",
    orderPlataform: "",
    name: "",
    contact: "",
    whatsapp: "",
    currency: "",
    paymentMethod: "",
    country: "",
    nonPaid: false,
    comission: false,
    discount: "",
    delivery: false,
    transfer: false,
    pax: "",
    registeredDate: formatedCurDate,
    purchaseDate: formatedCurDate,
    ticket1Sector: "",
    ticket1Date: "",
    ticket1Pax: "",
    ticket1Price: "",
    ticket1Transfer: "",
    ticket2Sector: "",
    ticket2Date: "",
    ticket2Pax: "",
    ticket2Price: "",
    ticket2Transfer: "",
    ticket3Sector: "",
    ticket3Date: "",
    ticket3Pax: "",
    ticket3Price: "",
    ticket3Transfer: "",
    ticket4Sector: "",
    ticket4Date: "",
    ticket4Pax: "",
    ticket4Price: "",
    ticket4Transfer: "",
    ticket5Sector: "",
    ticket5Date: "",
    ticket5Pax: "",
    ticket5Price: "",
    ticket5Transfer: "",
    comissionersName: "",
    comissionersContact: "",
    comissionCurrency: "",
    comissionPrice: "",
    comissionPaid: false,
    comissionByPercentage: false,
    comissionPercentage: 0,
  });
  const [formsStatus, setFormsStatus] = useState({});

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      if (e.target.name === "comission" && e.target.checked)
        setComissionModalOpened(true);
      if (e.target.name === "currency")
        newFormData.comissionCurrency = e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
      changeComissionPrice();
    },
    [formData]
  );

  const changeComissionPrice = useCallback(
    (e) => {
      if (formData.comissionByPercentage) {
        const price = (
          0.01 *
          formData.comissionPercentage *
          (parseFloat(formData.ticket1Price || 0) +
            parseFloat(formData.ticket2Price || 0) +
            parseFloat(formData.ticket3Price || 0) +
            parseFloat(formData.ticket4Price || 0) +
            parseFloat(formData.ticket5Price || 0))
        ).toFixed(2);
        let newFormData = formData;
        newFormData.comissionPrice = price;
        setFormData(newFormData);
        setFormsStatus(Math.random());
      }
    },
    [formsStatus, formData]
  );

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a, b) => {
          const dateA = a.value.split('/').reverse().join('-');
          const dateB = b.value.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        }).forEach((data) => transformedData.push(data.value));
        setDates(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTicketsNames(transformedData);
      });
  }, []);
  
  useEffect(() => {
    fetch(`${API_URL}dropdown-data/discounts.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setDiscounts(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/plataforms.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setPlataforms(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/currencies.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setCurrencies(transformedData);
      });

    fetch(`${API_URL}dropdown-data/transfers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTransfers(transformedData);
      });

      fetch(`${API_URL}dropdown-data/payment-methods.php`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          let transformedData = [];
          response.forEach((data) => transformedData.push(data.value));
          setPaymentMethods(transformedData);
        });
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;

      let body = {...formData, createdBy: userName, lastEditBy: userName};

      fetch(`${API_URL}tickets/create.php`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>Your order has been registered</i>,
              icon: "success",
            }).then(() => {
              return e.nativeEvent.submitter.getAttribute("create-new")
                ? navigate(0)
                : navigate("/order-list");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        });
    },
    [formData, userName, userPermissions]
  );


  return (
    <Main>
      <Sidebar></Sidebar>
      <Content>
        <SubTitle>Order</SubTitle>
        <Title>Input</Title>
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          status={formsStatus}
        >
          <FormRow>
            <TextField
              id="order-ref"
              label="#"
              variant="outlined"
              name="orderRef"
              onChange={onchange}
              value={formData.orderRef}
            />
            <FormControl fullWidth>
              <InputLabel id="order-plataform-label">Plataform</InputLabel>
              <Select
                labelId="order-plataform-label"
                id="order-plataform"
                name="orderPlataform"
                label="Plataform"
                value={formData.orderPlataform}
                onChange={onchange}
              >
                {plataforms.map((plataform) => {
                  return (
                    <MenuItem value={plataform} key={plataform}>
                      {plataform}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              name="name"
              onChange={onchange}
              value={formData.name}
            />
            <TextField
              id="contact"
              label="Email"
              variant="outlined"
              name="contact"
              onChange={onchange}
              value={formData.contact}
            />
            <TextField
              id="whatsapp"
              label="Whatsapp"
              variant="outlined"
              name="whatsapp"
              onChange={onchange}
              value={formData.whatsapp}
            />
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                label="Currency"
                name="currency"
                onChange={onchange}
                value={formData.currency}
              >
                {currencies.map((currency) => {
                  return (
                    <MenuItem value={currency} key={currency}>
                      {currency}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="payment-method-label">Payment Method</InputLabel>
              <Select
                labelId="payment-method-label"
                id="payment-method"
                label="Payment Method"
                name="paymentMethod"
                onChange={onchange}
                value={formData.paymentMethod}
              >
                {paymentMethods.map((paymentMethod) => {
                  return (
                    <MenuItem value={paymentMethod} key={paymentMethod}>
                      {paymentMethod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              name="country"
              onChange={onchange}
              value={formData.country}
            />
          </FormRow>
          <FormRow column noGap>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="non-paid"
                      name="nonPaid"
                      onChange={onchange}
                      checked={formData.nonPaid}
                    />
                  }
                  label="Non Paid"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="comission"
                      name="comission"
                      onChange={onchange}
                      checked={formData.comission}
                    />
                  }
                  label="Comission"
                />
              </FormGroup>
            </div>
          </FormRow>
          <FormRow column noGap>
            <InputLabel>Tickets</InputLabel>
            <TicketsContainer>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket1-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket1-label"
                    id="ticket1"
                    label="Ticket"
                    name="ticket1Sector"
                    value={formData.ticket1Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date1-label">Date</InputLabel>
                  <Select
                    labelId="date1-label"
                    id="date1"
                    label="Date"
                    name="ticket1Date"
                    value={formData.ticket1Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax1"
                  label="Pax"
                  variant="outlined"
                  name="ticket1Pax"
                  value={formData.ticket1Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price1"
                  label="Price"
                  variant="outlined"
                  name="ticket1Price"
                  value={formData.ticket1Price}
                  onChange={onchange}
                  type="number"
                />
                <FormControl fullWidth>
                  <Autocomplete
                    id="ticket1Transfer"
                    options={transfers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={formData.ticket1Transfer}
                    onChange={(e, newValue) =>
                      onchange({
                        target: {
                          name: "ticket1Transfer",
                          type: "select",
                          value: newValue,
                        },
                      })
                    }
                    renderValue={(selected) => selected.join(", ")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Transfer" placeholder="" />
                    )}
                  />
                </FormControl>
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket2-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket2-label"
                    id="ticket2"
                    label="Ticket"
                    name="ticket2Sector"
                    value={formData.ticket2Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date2-label">Date</InputLabel>
                  <Select
                    labelId="date2-label"
                    id="date2"
                    label="Date"
                    name="ticket2Date"
                    value={formData.ticket2Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax2"
                  label="Pax"
                  variant="outlined"
                  name="ticket2Pax"
                  value={formData.ticket2Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price2"
                  label="Price"
                  variant="outlined"
                  name="ticket2Price"
                  value={formData.ticket2Price}
                  onChange={onchange}
                  type="number"
                />
                <FormControl fullWidth>
                  <Autocomplete
                    id="ticket2Transfer"
                    options={transfers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={formData.ticket2Transfer}
                    onChange={(e, newValue) =>
                      onchange({
                        target: {
                          name: "ticket2Transfer",
                          type: "select",
                          value: newValue,
                        },
                      })
                    }
                    renderValue={(selected) => selected.join(", ")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Transfer" placeholder="" />
                    )}
                  />
                </FormControl>
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket3-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket3-label"
                    id="ticket3"
                    label="Ticket"
                    name="ticket3Sector"
                    value={formData.ticket3Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date3-label">Date</InputLabel>
                  <Select
                    labelId="date3-label"
                    id="date3"
                    label="Date"
                    name="ticket3Date"
                    value={formData.ticket3Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax3"
                  label="Pax"
                  variant="outlined"
                  name="ticket3Pax"
                  value={formData.ticket3Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price3"
                  label="Price"
                  variant="outlined"
                  name="ticket3Price"
                  value={formData.ticket3Price}
                  onChange={onchange}
                  type="number"
                />
                <FormControl fullWidth>
                  <Autocomplete
                    id="ticket3Transfer"
                    options={transfers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={formData.ticket3Transfer}
                    onChange={(e, newValue) =>
                      onchange({
                        target: {
                          name: "ticket3Transfer",
                          type: "select",
                          value: newValue,
                        },
                      })
                    }
                    renderValue={(selected) => selected.join(", ")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Transfer" placeholder="" />
                    )}
                  />
                </FormControl>
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket4-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket4-label"
                    id="ticket4"
                    label="Ticket"
                    name="ticket4Sector"
                    value={formData.ticket4Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date4-label">Date</InputLabel>
                  <Select
                    labelId="date4-label"
                    id="date4"
                    label="Date"
                    name="ticket4Date"
                    value={formData.ticket4Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax4"
                  label="Pax"
                  variant="outlined"
                  name="ticket4Pax"
                  value={formData.ticket4Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price4"
                  label="Price"
                  variant="outlined"
                  name="ticket4Price"
                  value={formData.ticket4Price}
                  onChange={onchange}
                  type="number"
                />
                <FormControl fullWidth>
                  <Autocomplete
                    id="ticket4Transfer"
                    options={transfers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={formData.ticket4Transfer}
                    onChange={(e, newValue) =>
                      onchange({
                        target: {
                          name: "ticket4Transfer",
                          type: "select",
                          value: newValue,
                        },
                      })
                    }
                    renderValue={(selected) => selected.join(", ")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Transfer" placeholder="" />
                    )}
                  />
                </FormControl>
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket5-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket5-label"
                    id="ticket5"
                    label="Ticket"
                    name="ticket5Sector"
                    value={formData.ticket5Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="date5-label">Date</InputLabel>
                  <Select
                    labelId="date5-label"
                    id="date5"
                    label="Date"
                    name="ticket5Date"
                    value={formData.ticket5Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax5"
                  label="Pax"
                  variant="outlined"
                  name="ticket5Pax"
                  value={formData.ticket5Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price5"
                  label="Price"
                  variant="outlined"
                  name="ticket5Price"
                  value={formData.ticket5Price}
                  onChange={onchange}
                  type="number"
                />
                <FormControl fullWidth>
                  <Autocomplete
                    id="ticket5Transfer"
                    options={transfers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    value={formData.ticket5Transfer}
                    onChange={(e, newValue) =>
                      onchange({
                        target: {
                          name: "ticket5Transfer",
                          type: "select",
                          value: newValue,
                        },
                      })
                    }
                    renderValue={(selected) => selected.join(", ")}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>{option}</li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Transfer" placeholder="" />
                    )}
                  />
                </FormControl>
              </TicketsRow>
            </TicketsContainer>
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <Autocomplete
                id="discount"
                options={discounts}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={formData.discount}
                onChange={(e, newValue) =>
                  onchange({
                    target: {
                      name: "discount",
                      type: "select",
                      value: newValue,
                    },
                  })
                }
                renderValue={(selected) => selected.join(", ")}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Discount"
                    placeholder="Search"
                  />
                )}
              />
            </FormControl>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="Delivery"
                      name="delivery"
                      onChange={onchange}
                      checked={formData.delivery}
                    />
                  }
                  label="Delivery"
                />
              </FormGroup>
            </div>
            <TextField
              id="purchaseDate"
              label="Purchase Date"
              variant="outlined"
              name="purchaseDate"
              value={formData.purchaseDate}
              type="date"
              onChange={onchange}
            />
            <TextField
              id="registeredDate"
              label="Registration Date"
              variant="outlined"
              name="registeredDate"
              value={formData.registeredDate}
              type="date"
              onChange={onchange}
            />
          </FormRow>
          <FormButtons>
            <Button variant="outlined" type="submit">
              Save
            </Button>
            <Button variant="outlined" type="submit" create-new="true">
              Save and create another
            </Button>
          </FormButtons>
        </FormBox>
        <ComissionModal opened={comissionModalOpened.toString()}>
          <ComissionModalHeader>
            <ComissionModalTitle>Comission Data</ComissionModalTitle>
            <ComissionModalClose
              onClick={() => setComissionModalOpened(false)}
            />
          </ComissionModalHeader>
          <FormBox
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
            status={formsStatus}
          >
            <FormRow>
              <TextField
                id="comissionersName"
                label="Comissioners Name"
                variant="outlined"
                name="comissionersName"
                onChange={onchange}
                value={formData.comissionersName}
              />
              <TextField
                id="comissionersContact"
                label="Comissioners Contact"
                variant="outlined"
                name="comissionersContact"
                onChange={onchange}
                value={formData.comissionersContact}
              />
            </FormRow>
            <FormRow>
              <FormControl fullWidth>
                <InputLabel id="comission-currency-label">Currency</InputLabel>
                <Select
                  labelId="comission-currency-label"
                  id="comissionCurrency"
                  label="Comission Currency"
                  name="comissionCurrency"
                  onChange={onchange}
                  value={formData.comissionCurrency}
                >
                  {currencies.map((currency) => {
                    return (
                      <MenuItem value={currency} key={currency}>
                        {currency}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                id="comissionPrice"
                label="Comission Price"
                variant="outlined"
                name="comissionPrice"
                onChange={onchange}
                value={formData.comissionPrice}
              />
            </FormRow>
            <FormRow>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="comissionPaid"
                      name="comissionPaid"
                      onChange={onchange}
                      checked={formData.comissionPaid}
                    />
                  }
                  label="Paid?"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="comissionByPercentage"
                      name="comissionByPercentage"
                      onChange={onchange}
                      checked={formData.comissionByPercentage}
                    />
                  }
                  label="Calc by percentage?"
                />
              </FormGroup>
              <TextField
                id="comissionPercentage"
                label="Comission Percentage"
                variant="outlined"
                name="comissionPercentage"
                onChange={onchange}
                value={formData.comissionPercentage}
                type="number"
                max="100"
                style={{
                  display: formData.comissionByPercentage ? "block" : "none",
                }}
              />
            </FormRow>
            <Button
              onClick={() => setComissionModalOpened(false)}
              variant="outlined"
            >
              OK
            </Button>
          </FormBox>
        </ComissionModal>
      </Content>
    </Main>
  );
};

export default OrderInput;
