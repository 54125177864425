import React, { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../../components/Sidebar";

import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  TicketsContainer,
  TicketsRow,
  FormButtons,
} from "./oderinputstore";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const getMonth = (date) => {
  var month = date.getMonth() + 1;
  return month < 10 ? "0" + month : "" + month;
};

const getDay = (date) => {
  var day = date.getDate();
  return day < 10 ? "0" + day : "" + day;
};

const OrderInputStore = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [ticketsNames, setTicketsNames] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [plataforms, setPlataforms] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const { userName, userPermissions } = useContext(StoreContext);

  const curDate = new Date();
  const formatedCurDate = `${curDate.getFullYear()}-${getMonth(
    curDate
  )}-${getDay(curDate)}`;

  const [formData, setFormData] = useState({
    orderRef: "",
    orderPlataform: "",
    name: "",
    contact: "",
    currency: "",
    country: "",
    discount: "",
    paymentMethod: "",
    pax: "",
    registeredDate: formatedCurDate,
    ticket1Ticket: "",
    ticket1Date: "",
    ticket1Pax: "",
    ticket1Price: "",
    ticket2Ticket: "",
    ticket2Date: "",
    ticket2Pax: "",
    ticket2Price: "",
    ticket3Ticket: "",
    ticket3Date: "",
    ticket3Pax: "",
    ticket3Price: "",
    ticket4Ticket: "",
    ticket4Date: "",
    ticket4Pax: "",
    ticket4Price: "",
    ticket5Ticket: "",
    ticket5Date: "",
    ticket5Pax: "",
    ticket5Price: "",
  });
  const [formsStatus, setFormsStatus] = useState({});

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;

      let body = formData;
      body.createdBy = userName;
      body.lastEditBy = userName;
      let ticket1 = formData.ticket1Ticket.split(" - ");
      body.ticket1Ticket = ticket1[0] || "";
      body.ticket1Date = ticket1[1] || "";
      let ticket2 = formData.ticket2Ticket.split(" - ");
      body.ticket2Ticket = ticket2[0] || "";
      body.ticket2Date = ticket2[1] || "";
      let ticket3 = formData.ticket3Ticket.split(" - ");
      body.ticket3Ticket = ticket3[0] || "";
      body.ticket3Date = ticket2[1] || "";
      let ticket4 = formData.ticket4Ticket.split(" - ");
      body.ticket4Ticket = ticket4[0] || "";
      body.ticket4Date = ticket4[1] || "";
      let ticket5 = formData.ticket5Ticket.split(" - ");
      body.ticket5Ticket = ticket5[0] || "";
      body.ticket5Date = ticket5[1] || "";

      fetch(`${API_URL}store/tickets/create.php`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>Your order has been registered</i>,
              icon: "success",
            }).then(() => {
              return e.nativeEvent.submitter.getAttribute("create-new")
                ? navigate(0)
                : navigate("/order-list-store");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        });
    },
    [formData, userName, userPermissions]
  );

  useEffect(() => {
    fetch(`${API_URL}store/dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.ticket));
        setTicketsNames(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}store/dropdown-data/discounts.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setDiscounts(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}store/dropdown-data/plataforms.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setPlataforms(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}store/dropdown-data/currencies.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setCurrencies(transformedData);
      });
    fetch(`${API_URL}store/dropdown-data/payment-methods.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setPaymentMethods(transformedData);
      });
  }, []);

  return (
    <Main>
      <Sidebar></Sidebar>
      <Content>
        <SubTitle>Order</SubTitle>
        <Title>Input - Store</Title>
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          status={formsStatus}
        >
          <FormRow>
            <TextField
              id="order-ref"
              label="#"
              variant="outlined"
              name="orderRef"
              onChange={onchange}
              value={formData.orderRef}
            />
            <FormControl fullWidth>
              <InputLabel id="order-plataform-label">Plataform</InputLabel>
              <Select
                labelId="order-plataform-label"
                id="order-plataform"
                name="orderPlataform"
                label="Plataform"
                value={formData.orderPlataform}
                onChange={onchange}
              >
                {plataforms.map((plataform) => {
                  return (
                    <MenuItem value={plataform} key={plataform}>
                      {plataform}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              name="name"
              onChange={onchange}
              value={formData.name}
            />
            <TextField
              id="contact"
              label="Email/whatsapp"
              variant="outlined"
              name="contact"
              onChange={onchange}
              value={formData.contact}
            />
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                label="Currency"
                name="currency"
                onChange={onchange}
                value={formData.currency}
              >
                {currencies.map((currency) => {
                  return (
                    <MenuItem value={currency} key={currency}>
                      {currency}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              name="country"
              onChange={onchange}
              value={formData.country}
            />
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="payment-method-label">Payment Method</InputLabel>
              <Select
                labelId="payment-method-label"
                id="paymentMethod"
                label="PaymentMethod"
                name="paymentMethod"
                onChange={onchange}
                value={formData.paymentMethod}
              >
                {paymentMethods.map((paymentMethod) => {
                  return (
                    <MenuItem value={paymentMethod} key={paymentMethod}>
                      {paymentMethod}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div></div>
          </FormRow>
          <FormRow column noGap>
            <InputLabel>Items</InputLabel>
            <TicketsContainer>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket1-label">Product</InputLabel>
                  <Select
                    labelId="ticket1-label"
                    id="ticket1"
                    label="Ticket"
                    name="ticket1Ticket"
                    value={formData.ticket1Ticket}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax1"
                  label="Quantity"
                  variant="outlined"
                  name="ticket1Pax"
                  value={formData.ticket1Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price1"
                  label="Price"
                  variant="outlined"
                  name="ticket1Price"
                  value={formData.ticket1Price}
                  onChange={onchange}
                  type="number"
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket2-label">Product</InputLabel>
                  <Select
                    labelId="ticket2-label"
                    id="ticket2"
                    label="Ticket"
                    name="ticket2Ticket"
                    value={formData.ticket2Ticket}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax2"
                  label="Quantity"
                  variant="outlined"
                  name="ticket2Pax"
                  value={formData.ticket2Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price2"
                  label="Price"
                  variant="outlined"
                  name="ticket2Price"
                  value={formData.ticket2Price}
                  onChange={onchange}
                  type="number"
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket3-label">Product</InputLabel>
                  <Select
                    labelId="ticket3-label"
                    id="ticket3"
                    label="Ticket"
                    name="ticket3Ticket"
                    value={formData.ticket3Ticket}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax3"
                  label="Quantity"
                  variant="outlined"
                  name="ticket3Pax"
                  value={formData.ticket3Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price3"
                  label="Price"
                  variant="outlined"
                  name="ticket3Price"
                  value={formData.ticket3Price}
                  onChange={onchange}
                  type="number"
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket4-label">Product</InputLabel>
                  <Select
                    labelId="ticket4-label"
                    id="ticket4"
                    label="Ticket"
                    name="ticket4Ticket"
                    value={formData.ticket4Ticket}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax4"
                  label="Quantity"
                  variant="outlined"
                  name="ticket4Pax"
                  value={formData.ticket4Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price4"
                  label="Price"
                  variant="outlined"
                  name="ticket4Price"
                  value={formData.ticket4Price}
                  onChange={onchange}
                  type="number"
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket5-label">Product</InputLabel>
                  <Select
                    labelId="ticket5-label"
                    id="ticket5"
                    label="Ticket"
                    name="ticket5Ticket"
                    value={formData.ticket5Ticket}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="pax5"
                  label="Quantity"
                  variant="outlined"
                  name="ticket5Pax"
                  value={formData.ticket5Pax}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price5"
                  label="Price"
                  variant="outlined"
                  name="ticket5Price"
                  value={formData.ticket5Price}
                  onChange={onchange}
                  type="number"
                />
              </TicketsRow>
            </TicketsContainer>
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <Autocomplete
                id="discount"
                options={discounts}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={formData.discount}
                onChange={(e, newValue) =>
                  onchange({
                    target: {
                      name: "discount",
                      type: "select",
                      value: newValue,
                    },
                  })
                }
                renderValue={(selected) => selected.join(", ")}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Discount"
                    placeholder="Search"
                  />
                )}
              />
            </FormControl>
            <TextField
              id="registeredDate"
              label="Registration Date"
              variant="outlined"
              name="registeredDate"
              value={formData.registeredDate}
              type="date"
              onChange={onchange}
            />
          </FormRow>
          <FormButtons>
            <Button variant="outlined" type="submit">
              Save
            </Button>
            <Button variant="outlined" type="submit" create-new="true">
              Save and create another
            </Button>
          </FormButtons>
        </FormBox>
      </Content>
    </Main>
  );
};

export default OrderInputStore;
