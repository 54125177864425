import React, { useState, useCallback, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import { TextField, Button } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Textarea from "@mui/joy/Textarea";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  Block,
  EditSeveralModal,
  EditSeveralModalTitle,
  CloseEditSeveralModal,
  EditSeveralModalSubtitle,
  Overlay,
} from "./transferupdate";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const TransferUpdate = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const transferId = params.get("id");

  const [dates, setDates] = useState([]);
  const [plataforms, setPlataforms] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [ticketsNames, setTicketsNames] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [userBlocking, setUserBlocking] = useState("");
  const [originalOrderRef, setOriginalOrderRef] = useState("");
  const [recordsToUpdate, setRecordsToUpdate] = useState([]);
  const [
    selectRecordsToUpdateModalOpened,
    setSelectRecordsToUpdateModalOpened,
  ] = useState(false);

  const { userName, userUsername, userPermissions } = useContext(StoreContext);

  const [formData, setFormData] = useState({
    orderRef: "",
    plataform: "",
    name: "",
    pax: "",
    ticketsDate: "",
    contact: "",
    country: "",
    sector: "",
    pickupPoint: "",
    currency: "",
    price: "",
    comments: "",
    dateOfRegistration: "",
    transferType: "",
  });
  const [formsStatus, setFormsStatus] = useState({});

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const handleSelectRecordsToUpdate = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;
      fetch(`${API_URL}getRecordsByOrderRef.php?order-ref=${originalOrderRef}`)
        .then((response) => response.json())
        .then((response) => {
          response = response.filter((r) => r.type !== "transfer");
          setRecordsToUpdate(response);
          setSelectRecordsToUpdateModalOpened(true);
          setFormsStatus(Math.random());
        });
    },
    [originalOrderRef, userPermissions]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      let body = formData;
      body.lastEditBy = userName;
      body.createdBy = userName;

      e.preventDefault();
      const elements = Array.from(e.target.elements);
      let recordsToUpdate = [];
      elements.forEach((el) => {
        if (el.tagName === "INPUT") {
          const type = el.id.split("-")[0].trim();
          const id = el.id.split("-")[1].trim();
          if (el.checked) {
            recordsToUpdate.push({
              type,
              id,
            });
          }
        }
      });

      body.recordsToUpdate = recordsToUpdate;

      fetch(`${API_URL}transfers/update.php?id=${formData.id}`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>Your transfer has been updated</i>,
              icon: "success",
            }).then(() => {
              return navigate("/transfer-list");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    },
    [formData, userName]
  );

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a, b) => {
          const dateA = a.value.split('/').reverse().join('-');
          const dateB = b.value.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        }).forEach((data) => transformedData.push(data.value));
        setDates(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/plataforms.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setPlataforms(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/currencies.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setCurrencies(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTicketsNames(transformedData);
      });
    fetch(`${API_URL}dropdown-data/transfers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTransfers(transformedData);
      });
  }, []);

  useEffect(() => {
    if (!transferId) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Transfer not found!",
      }).then(() => {
        navigate("/transfer-list");
      });
    } else {
      fetch(`${API_URL}transfers/list-by-id.php?transfer_id=${transferId}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((response) => {
          setOriginalOrderRef(response.orderRef);
          setFormData(response);
        });
    }
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (userName && userUsername) {
        const body = {
          page: `${location.pathname}${location.search}`,
          username: userUsername,
          name: userName,
        };
        fetch(`${API_URL}locks/unlock.php`, {
          method: "POST",
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
          });
      }
    };
  }, [userName, userUsername, location]);

  return (
    <Main>
      <Sidebar></Sidebar>
      <Content blocked={blocked.toString()}>
        <SubTitle>Transfer</SubTitle>
        <Title>Update</Title>
        {blocked && (
          <Block>
            This page is temporarily blocked because it is being edited by{" "}
            {userBlocking}{" "}
          </Block>
        )}
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSelectRecordsToUpdate}
          status={formsStatus}
          blocked={selectRecordsToUpdateModalOpened.toString()}
        >
          <FormRow>
            <TextField
              id="order-ref"
              label="#"
              variant="outlined"
              name="orderRef"
              onChange={onchange}
              value={formData.orderRef}
            />
            <FormControl fullWidth>
              <InputLabel id="order-plataform-label">Plataform</InputLabel>
              <Select
                labelId="order-plataform-label"
                id="plataform"
                name="plataform"
                label="Plataform"
                value={formData.plataform}
                onChange={onchange}
              >
                {plataforms.map((plataform) => {
                  return (
                    <MenuItem value={plataform} key={plataform}>
                      {plataform}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              name="name"
              onChange={onchange}
              value={formData.name}
            />
            <TextField
              id="contact"
              label="Email/whatsapp"
              variant="outlined"
              name="contact"
              onChange={onchange}
              value={formData.contact}
            />
          </FormRow>
          <FormRow>
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              name="country"
              onChange={onchange}
              value={formData.country}
            />
            <FormControl fullWidth>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                label="Currency"
                name="currency"
                onChange={onchange}
                value={formData.currency}
              >
                {currencies.map((currency) => {
                  return (
                    <MenuItem value={currency} key={currency}>
                      {currency}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="pax"
              label="Pax"
              variant="outlined"
              name="pax"
              onChange={onchange}
              value={formData.pax}
              type="number"
            />
            <FormControl fullWidth>
              <InputLabel id="tickets-date-label">Tickets Date</InputLabel>
              <Select
                labelId="tickets-date-label"
                id="ticketsDate"
                label="Tickets Date"
                name="ticketsDate"
                onChange={onchange}
                value={formData.ticketsDate}
              >
                {dates.map((date) => {
                  return (
                    <MenuItem value={date} key={date}>
                      {date}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="tickets-label">Sector</InputLabel>
              <Select
                labelId="tickets-label"
                id="sector"
                label="Sector"
                name="sector"
                onChange={onchange}
                value={formData.sector}
              >
                {ticketsNames.map((sector) => {
                  return (
                    <MenuItem value={sector} key={sector}>
                      {sector}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              id="price"
              label="Price"
              variant="outlined"
              name="price"
              onChange={onchange}
              value={formData.price}
              type="number"
            />
          </FormRow>
          <FormRow>
            {" "}
            <TextField
              id="dateOfRegistration"
              label="Date of Registration"
              variant="outlined"
              name="dateOfRegistration"
              onChange={onchange}
              value={formData.dateOfRegistration}
              type="date"
            />
            <TextField
              id="pickupPoint"
              label="Pickup Point"
              variant="outlined"
              name="pickupPoint"
              onChange={onchange}
              value={formData.pickupPoint}
            />
          </FormRow>
          <FormRow>
            <FormControl fullWidth>
              <Autocomplete
                id="transferType"
                options={transfers}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={formData.transferType}
                onChange={(e, newValue) =>
                  onchange({
                    target: {
                      name: "transferType",
                      type: "select",
                      value: newValue,
                    },
                  })
                }
                renderValue={(selected) => selected.join(", ")}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Transfer Type" placeholder="" />
                )}
              />
            </FormControl>
            <Textarea
              minRows={2}
              placeholder="Comments"
              id="comments"
              name="comments"
              onChange={onchange}
              value={formData.comments}
            />
          </FormRow>

          <Button variant="outlined" type="submit">
            Save
          </Button>
        </FormBox>
        <Overlay opened={selectRecordsToUpdateModalOpened.toString()}>
          <EditSeveralModal
            status={formsStatus}
            opened={selectRecordsToUpdateModalOpened.toString()}
          >
            <EditSeveralModalTitle>
              Select others records to update on order-ref {originalOrderRef}
            </EditSeveralModalTitle>
            <EditSeveralModalSubtitle>
              This will update fields "#, Plataform, Name, Email/Whatsapp,
              Country, Currency" in all selected records.
            </EditSeveralModalSubtitle>
            <CloseEditSeveralModal
              onClick={() => setSelectRecordsToUpdateModalOpened(false)}
            />
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
            >
              {recordsToUpdate.map((record) => (
                <FormGroup key={`${record.id}-${record.type}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`${record.type} - ${record.id}`}
                        name={record.id}
                        record-type={record.type}
                        inputProps={{
                          "record-type": record.type,
                        }}
                      />
                    }
                    label={`${record.type} ${
                      record.date ? `- ${record.date}` : ""
                    } ${record.sector ? `- ${record.sector}` : ""}`}
                  />
                </FormGroup>
              ))}
              <Button variant="outlined" type="submit">
                Save
              </Button>
            </FormBox>
          </EditSeveralModal>
        </Overlay>
      </Content>
    </Main>
  );
};

export default TransferUpdate;
