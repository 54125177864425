import React, { useState, useCallback, useEffect, useContext } from "react";
import Sidebar from "../../components/Sidebar";
import { TextField, Button } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  TicketsContainer,
  TicketsRow,
  FormButtons,
} from "./internalpurchasefloripa";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const InternalPurchaseFloripa = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [dates, setDates] = useState([]);
  const [ticketsNames, setTicketsNames] = useState([]);
  const [authorizers, setAuthorizers] = useState([]);
  const [buyers, setBuyers] = useState([]);

  const { userName, userPermissions } = useContext(StoreContext);

  const [formData, setFormData] = useState({
    buyer: "",
    authorizing: "",
    purchaseDate: "",
    ticketProvider: "",
    ticket1Sector: "",
    ticket1Date: "",
    ticket1Quantity: 0,
    ticket1Price: "",
    ticket2Sector: "",
    ticket2Date: "",
    ticket2Quantity: 0,
    ticket2Price: "",
    ticket3Sector: "",
    ticket3Date: "",
    ticket3Quantity: 0,
    ticket3Price: "",
    ticket4Sector: "",
    ticket4Date: "",
    ticket4Quantity: 0,
    ticket4Price: "",
    ticket5Sector: "",
    ticket5Date: "",
    ticket5Quantity: 0,
    ticket5Price: "",
  });
  const [formsStatus, setFormsStatus] = useState({});

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;

      let body = formData;
      body.createdBy = userName;
      body.lastEditBy = userName;

      fetch(`${API_URL}floripa/tickets/create-internal-purchase.php`, {
        method: "POST",
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>Your order has been registered</i>,
              icon: "success",
            }).then(() => {
              return e.nativeEvent.submitter.getAttribute("create-new")
                ? navigate(0)
                : navigate("/inventory-floripa");
            });
          }
        });
    },
    [formData, userName, userPermissions]
  );

  useEffect(() => {
    fetch(`${API_URL}floripa/dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.ticket));
        setTicketsNames(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}floripa/dropdown-data/authorizers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setAuthorizers(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}floripa/dropdown-data/buyers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setBuyers(transformedData);
      });
  }, []);

  return (
    <Main>
      <Sidebar></Sidebar>
      <Content>
        <SubTitle>Internal</SubTitle>
        <Title>Purchase</Title>
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          status={formsStatus}
        >
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="buyer-label">Buyer</InputLabel>
              <Select
                labelId="buyer-label"
                id="buyer"
                name="buyer"
                label="Buyer"
                value={formData.buyer}
                onChange={onchange}
              >
                {buyers.map((buyer) => {
                  return (
                    <MenuItem value={buyer} key={buyer}>
                      {buyer}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="authorizing-label">Authorizing</InputLabel>
              <Select
                labelId="authorizing-label"
                id="authorizing"
                name="authorizing"
                label="Authorizing"
                value={formData.authorizing}
                onChange={onchange}
              >
                {authorizers.map((authorizer) => {
                  return (
                    <MenuItem value={authorizer} key={authorizer}>
                      {authorizer}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="purchaseDate"
              label="Purchase Date"
              variant="outlined"
              name="purchaseDate"
              onChange={onchange}
              value={formData.purchaseDate}
              type="date"
              placeholder=""
            />
            <TextField
              id="ticketProvider"
              label="Ticket Provider"
              variant="outlined"
              name="ticketProvider"
              onChange={onchange}
              value={formData.ticketProvider}
            />
          </FormRow>
          <FormRow column noGap>
            <InputLabel>Tickets Information</InputLabel>
            <TicketsContainer>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket1-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket1-label"
                    id="ticket1"
                    label="Ticket"
                    name="ticket1Sector"
                    value={formData.ticket1Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket1Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket1Quantity"
                  value={formData.ticket1Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price1"
                  label="Price"
                  variant="outlined"
                  name="ticket1Price"
                  value={formData.ticket1Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket2-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket2-label"
                    id="ticket2"
                    label="Ticket"
                    name="ticket2Sector"
                    value={formData.ticket2Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket2Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket2Quantity"
                  value={formData.ticket2Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price2"
                  label="Price"
                  variant="outlined"
                  name="ticket2Price"
                  value={formData.ticket2Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket3-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket3-label"
                    id="ticket3"
                    label="Ticket"
                    name="ticket3Sector"
                    value={formData.ticket3Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket3Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket3Quantity"
                  value={formData.ticket3Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price3"
                  label="Price"
                  variant="outlined"
                  name="ticket3Price"
                  value={formData.ticket3Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket4-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket4-label"
                    id="ticket4"
                    label="Ticket"
                    name="ticket4Sector"
                    value={formData.ticket4Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket4Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket4Quantity"
                  value={formData.ticket4Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price4"
                  label="Price"
                  variant="outlined"
                  name="ticket4Price"
                  value={formData.ticket4Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="ticket5-label">Ticket</InputLabel>
                  <Select
                    labelId="ticket5-label"
                    id="ticket5"
                    label="Ticket"
                    name="ticket5Sector"
                    value={formData.ticket5Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket5Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket5Quantity"
                  value={formData.ticket5Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price5"
                  label="Price"
                  variant="outlined"
                  name="ticket5Price"
                  value={formData.ticket5Price}
                  onChange={onchange}
                />
              </TicketsRow>
            </TicketsContainer>
          </FormRow>
          <FormButtons>
            <Button variant="outlined" type="submit">
              Save
            </Button>
            <Button variant="outlined" type="submit" create-new="true">
              Save and create another
            </Button>
          </FormButtons>
        </FormBox>
      </Content>
    </Main>
  );
};

export default InternalPurchaseFloripa;
