import React, { useState, useCallback, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import { TextField, Button } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import {
  Content,
  Main,
  SubTitle,
  Title,
  FormBox,
  FormRow,
  TicketsContainer,
  TicketsRow,
} from "./internalpurchaseupdatetransfer";

import { useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/env";

const InternalPurchaseUpdateTransfer = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const internalPurchaseId = params.get("id");

  const [dates, setDates] = useState([]);
  const [ticketsNames, setTicketsNames] = useState([]);
  const [authorizers, setAuthorizers] = useState([]);
  const [buyers, setBuyers] = useState([]);

  const { userName, userPermissions } = useContext(StoreContext);

  const [formData, setFormData] = useState({
    buyer: "",
    authorizing: "",
    purchaseDate: "",
    ticketProvider: "",
    ticket1Sector: "",
    ticket1Date: "",
    ticket1Quantity: "",
    ticket1Price: "",
    ticket2Sector: "",
    ticket2Date: "",
    ticket2Quantity: "",
    ticket2Price: "",
    ticket3Sector: "",
    ticket3Date: "",
    ticket3Quantity: "",
    ticket3Price: "",
    ticket4Sector: "",
    ticket4Date: "",
    ticket4Quantity: "",
    ticket4Price: "",
    ticket5Sector: "",
    ticket5Date: "",
    ticket5Quantity: "",
    ticket5Price: "",
  });
  const [formsStatus, setFormsStatus] = useState({});

  const onchange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;

      let body = formData;
      body.createdBy = userName;
      body.lastEditBy = userName;

      fetch(
        `${API_URL}transfers/update-internal-purchase.php?id=${formData.internalPurchaseId}`,
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>Your internal purchase has been updated</i>,
              icon: "success",
            }).then(() => {
              return navigate("/inventory-transfer");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    },
    [formData, userName, userPermissions]
  );

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a, b) => {
          const dateA = a.value.split('/').reverse().join('-');
          const dateB = b.value.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        }).forEach((data) => transformedData.push(data.value));
        setDates(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/transfers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setTicketsNames(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/authorizers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setAuthorizers(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/buyers.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        setBuyers(transformedData);
      });
  }, []);

  useEffect(() => {
    if (!internalPurchaseId) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Internal purchase not found!",
      }).then(() => {
        navigate("/inventory-transfer");
      });
    } else {
      fetch(
        `${API_URL}transfers/list-internal-purchase-by-id.php?internal_purchase_id=${internalPurchaseId}`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((response) => {
          let newData = {};
          response.forEach((ticket, index) => {
            if (index === 0) {
              newData = {
                internalPurchaseId: ticket.internalPurchaseId,
                buyer: ticket.buyer,
                authorizing: ticket.authorizing,
                purchaseDate: ticket.purchaseDate,
                ticketProvider: ticket.ticketProvider,
                ticket1Sector: ticket.transferType,
                ticket1Date: ticket.ticketsDate,
                ticket1Quantity: ticket.quantity,
                ticket1Price: ticket.price,
                ticket1Id: ticket.id,
              };
            } else {
              newData[`ticket${index + 1}Sector`] = ticket.transferType;
              newData[`ticket${index + 1}Date`] = ticket.ticketsDate;
              newData[`ticket${index + 1}Quantity`] = ticket.quantity;
              newData[`ticket${index + 1}Price`] = ticket.price;
              newData[`ticket${index + 1}Id`] = ticket.id;
            }
          });
          setFormData({ ...formData, ...newData });
        });
    }
  }, []);

  return (
    <Main>
      <Sidebar></Sidebar>
      <Content>
        <SubTitle>Update</SubTitle>
        <Title>Internal Purchase - Transfer</Title>
        <FormBox
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
          status={formsStatus}
        >
          <FormRow>
            <FormControl fullWidth>
              <InputLabel id="buyer-label">Buyer</InputLabel>
              <Select
                labelId="buyer-label"
                id="buyer"
                name="buyer"
                label="Buyer"
                value={formData.buyer}
                onChange={onchange}
              >
                {buyers.map((buyer) => {
                  return (
                    <MenuItem value={buyer} key={buyer}>
                      {buyer}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="authorizing-label">Authorizing</InputLabel>
              <Select
                labelId="authorizing-label"
                id="authorizing"
                name="authorizing"
                label="Authorizing"
                value={formData.authorizing}
                onChange={onchange}
              >
                {authorizers.map((authorizer) => {
                  return (
                    <MenuItem value={authorizer} key={authorizer}>
                      {authorizer}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </FormRow>
          <FormRow>
            <TextField
              id="purchaseDate"
              label="Purchase Date"
              variant="outlined"
              name="purchaseDate"
              onChange={onchange}
              value={formData.purchaseDate}
              type="date"
              placeholder=""
            />
            <TextField
              id="ticketProvider"
              label="Ticket Provider"
              variant="outlined"
              name="ticketProvider"
              onChange={onchange}
              value={formData.ticketProvider}
            />
          </FormRow>
          <FormRow column noGap>
            <InputLabel>Transfers Information</InputLabel>
            <TicketsContainer>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="date1-label">Date</InputLabel>
                  <Select
                    labelId="date1-label"
                    id="date1"
                    label="Date"
                    name="ticket1Date"
                    value={formData.ticket1Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="ticket1-label">Transfer Type</InputLabel>
                  <Select
                    labelId="ticket1-label"
                    id="ticket1"
                    label="Ticket"
                    name="ticket1Sector"
                    value={formData.ticket1Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket1Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket1Quantity"
                  value={formData.ticket1Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price1"
                  label="Price"
                  variant="outlined"
                  name="ticket1Price"
                  value={formData.ticket1Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="date2-label">Date</InputLabel>
                  <Select
                    labelId="date2-label"
                    id="ticket2Date"
                    label="Date"
                    name="ticket2Date"
                    value={formData.ticket2Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="ticket2-label">Transfer Type</InputLabel>
                  <Select
                    labelId="ticket2-label"
                    id="ticket2"
                    label="Ticket"
                    name="ticket2Sector"
                    value={formData.ticket2Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket2Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket2Quantity"
                  value={formData.ticket2Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price2"
                  label="Price"
                  variant="outlined"
                  name="ticket2Price"
                  value={formData.ticket2Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="date3-label">Date</InputLabel>
                  <Select
                    labelId="date3-label"
                    id="date3"
                    label="Date"
                    name="ticket3Date"
                    value={formData.ticket3Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="ticket3-label">Transfer Type</InputLabel>
                  <Select
                    labelId="ticket3-label"
                    id="ticket3"
                    label="Ticket"
                    name="ticket3Sector"
                    value={formData.ticket3Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket3Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket3Quantity"
                  value={formData.ticket3Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price3"
                  label="Price"
                  variant="outlined"
                  name="ticket3Price"
                  value={formData.ticket3Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="date4-label">Date</InputLabel>
                  <Select
                    labelId="date4-label"
                    id="date4"
                    label="Date"
                    name="ticket4Date"
                    value={formData.ticket4Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="ticket4-label">Transfer Type</InputLabel>
                  <Select
                    labelId="ticket4-label"
                    id="ticket4"
                    label="Ticket"
                    name="ticket4Sector"
                    value={formData.ticket4Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket4Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket4Quantity"
                  value={formData.ticket4Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price4"
                  label="Price"
                  variant="outlined"
                  name="ticket4Price"
                  value={formData.ticket4Price}
                  onChange={onchange}
                />
              </TicketsRow>
              <TicketsRow>
                <FormControl fullWidth>
                  <InputLabel id="date5-label">Date</InputLabel>
                  <Select
                    labelId="date5-label"
                    id="date5"
                    label="Date"
                    name="ticket5Date"
                    value={formData.ticket5Date}
                    onChange={onchange}
                  >
                    {dates.map((date) => {
                      return (
                        <MenuItem value={date} key={date}>
                          {date}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="ticket5-label">Transfer Type</InputLabel>
                  <Select
                    labelId="ticket5-label"
                    id="ticket5"
                    label="Ticket"
                    name="ticket5Sector"
                    value={formData.ticket5Sector}
                    onChange={onchange}
                  >
                    {ticketsNames.map((ticket) => {
                      return (
                        <MenuItem value={ticket} key={ticket}>
                          {ticket}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  id="ticket5Quantity"
                  label="Quantity"
                  variant="outlined"
                  name="ticket5Quantity"
                  value={formData.ticket5Quantity}
                  onChange={onchange}
                  type="number"
                />
                <TextField
                  id="price5"
                  label="Price"
                  variant="outlined"
                  name="ticket5Price"
                  value={formData.ticket5Price}
                  onChange={onchange}
                />
              </TicketsRow>
            </TicketsContainer>
          </FormRow>
          <Button variant="outlined" type="submit">
            Save
          </Button>
        </FormBox>
      </Content>
    </Main>
  );
};

export default InternalPurchaseUpdateTransfer;
