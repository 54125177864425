import React, { useState, useEffect, useCallback } from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Autocomplete,
  Box,
  Divider,
  Paper,
  FormControlLabel,
} from "@mui/material";

import { Filters } from "./styles";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { API_URL } from "../../utils/env";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const COLORS = [
  "#fe7f7c",
  "#abdee6",
  "#cbaacb",
  "#ffccb6",
  "#f3b0c3",
  "#c6dbda",
  "#fee1e8",
  "#f6eac2",
  "#55cbcd",
  "#ff968a",
];

const months = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2];

function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", { month: "short" });
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TicketsPerYear = (setReset) => {
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [filteredTicket, setFilteredTicket] = useState([]);
  const [removedYears, setRemovedYears] = useState([]);
  const carnavalDays = [
    "access-1",
    "access-2",
    "special-1",
    "special-2",
    "winners-parade",
    "",
  ];
  const [filteredCarnavalDay, setFilteredCarnavalDay] = useState([
    "access-1",
    "access-2",
    "special-1",
    "special-2",
    "special-3",
    "winners-parade",
    "",
  ]);
  const [filteredCurrency, setFilteredCurrency] = useState([]);

  const [ticketsNames, setTicketsNames] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [dataTicketsByMonth, setDataTicketsByMonth] = useState([]);
  const [limitTicketsByMonth, setLimitTicketsByMonth] = useState(0);
  const [ticketsByMonthsYears, setTicketsByMonthsYears] = useState([]);

  const handleFilterYears = useCallback(
    (props) => {
      let newRemovedYears = removedYears;
      let year = props.dataKey;
      if (year.includes("-off")) {
        year = year.replace("-off", "");
      }
      if (newRemovedYears.indexOf(year) !== -1) {
        newRemovedYears.splice(newRemovedYears.indexOf(year), 1);
      } else {
        newRemovedYears.push(year);
      }
      setRemovedYears(newRemovedYears);
      setReset(Math.random());
    },
    [removedYears]
  );

  useEffect(() => {
    fetch(`${API_URL}charts/initial-and-final-dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setInitialDate(response.initial);
          setFinalDate(response.final);
        }
      });

    fetch(`${API_URL}dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        transformedData.push("TRANSFER");
        transformedData.push("DELIVERY");
        transformedData.push("");
        setTicketsNames(transformedData);
        setFilteredTicket(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/currencies.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((data) => transformedData.push(data.value));
        transformedData.push("");
        setCurrencies(transformedData);
        setFilteredCurrency(transformedData);
      });
  }, []);

  useEffect(() => {
    if (!initialDate || !finalDate) return;
    fetch(
      `${API_URL}charts/tickets-per-month.php?initial=${initialDate}&final=${finalDate}&ticket=${filteredTicket}&carnavalDay=${
        filteredCarnavalDay.length > 0 ? filteredCarnavalDay : "NULL"
      }&currency=${filteredCurrency.length > 0 ? filteredCurrency : "NULL"}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        let formatedData = [];
        let years = [];
        months.forEach((m) =>
          formatedData.push({
            month: m.toString(),
            name: getMonthName(m),
            inactive: true,
          })
        );
        response.forEach((item) => {
          if (!formatedData.find((i) => i.month === item.month)) {
            let obj = {};
            obj.name = getMonthName(item.month);
            obj.month = item.month;
            obj[`${item["year"]}`] = item["Sales"];
            formatedData.push(obj);
          } else {
            formatedData.find((i) => i.month === item.month)[
              `${item["year"]}`
            ] =
              parseInt(item["Sales"]) +
              parseInt(
                formatedData.find((i) => i.month === item.month)[
                  `${item["year"]}`
                ] || 0
              );
          }
          if (years.indexOf(item.year) === -1) years.push(item.year);
        });
        setDataTicketsByMonth(formatedData);
        let limit = 0;
        formatedData.forEach((item) => {
          years.forEach((year) => {
            if (parseInt(item[year]) > limit) limit = parseInt(item[year]);
          });
        });
        setLimitTicketsByMonth(parseInt(1.1 * limit));
        setTicketsByMonthsYears(years);
      });
  }, [
    initialDate,
    finalDate,
    filteredTicket,
    filteredCarnavalDay,
    filteredCurrency,
  ]);

  return (
    <>
      <Filters>
        <TextField
          id="initialDate"
          label="Initial Date"
          variant="outlined"
          name="initialDate"
          onChange={(e) => setInitialDate(e.target.value)}
          value={initialDate}
          type="date"
          placeholder=""
        />
        <TextField
          id="finalDate"
          label="Final Date"
          variant="outlined"
          name="finalDate"
          onChange={(e) => setFinalDate(e.target.value)}
          value={finalDate}
          type="date"
          placeholder=""
        />
        <FormControl fullWidth>
          <Autocomplete
            multiple
            id="product-filter"
            options={ticketsNames}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={filteredTicket}
            onChange={(e, newValue) => setFilteredTicket(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Product" placeholder="Search" />
            )}
            renderTags={(list) => {
              let displayList = list.map((item) => item).join(", ");
              return (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {displayList}
                </span>
              );
            }}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        filteredTicket.length !== ticketsNames.length
                          ? setFilteredTicket(ticketsNames)
                          : setFilteredTicket([]);
                      }}
                      label="Select all"
                      htmlFor="select-all-products-checkbox"
                      control={
                        <Checkbox
                          id="select-all-products-checkbox"
                          checked={
                            filteredTicket.length === ticketsNames.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            id="carnaval-day-filter"
            options={carnavalDays}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={filteredCarnavalDay}
            onChange={(e, newValue) => setFilteredCarnavalDay(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Carnaval Day"
                placeholder="Search"
              />
            )}
            renderTags={(list) => {
              let displayList = list.map((item) => item).join(", ");
              return (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {displayList}
                </span>
              );
            }}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        filteredCarnavalDay.length !== carnavalDays.length
                          ? setFilteredCarnavalDay(carnavalDays)
                          : setFilteredCarnavalDay([]);
                      }}
                      label="Select all"
                      htmlFor="select-all-carnavalDays-checkbox"
                      control={
                        <Checkbox
                          id="select-all-carnavalDays-checkbox"
                          checked={
                            filteredCarnavalDay.length === carnavalDays.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="currency-label">Currency</InputLabel>
          <Select
            labelId="currency-label"
            id="currency"
            name="currency"
            label="currency"
            value={filteredCurrency}
            onChange={(e) => setFilteredCurrency(e.target.value)}
            multiple
            MenuProps={MenuProps}
            renderValue={(selected) => selected.join(", ")}
            input={<OutlinedInput label="Tag" />}
          >
            {currencies.map((currency) => {
              return (
                <MenuItem value={currency} key={currency}>
                  <Checkbox checked={filteredCurrency.indexOf(currency) > -1} />
                  <ListItemText primary={currency} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Filters>
      <h1>Sales PER MONTH</h1>
      <BarChart
        width={800}
        height={500}
        data={dataTicketsByMonth}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, limitTicketsByMonth]} />
        <Tooltip />
        <Legend onClick={handleFilterYears} />
        {ticketsByMonthsYears.map((year, index) => (
          <Bar
            key={year}
            type="monotone"
            dataKey={removedYears.indexOf(year) === -1 ? year : `${year}-off`}
            fill={COLORS[index % COLORS.length]}
            barSize={25}
          />
        ))}
      </BarChart>
    </>
  );
};

export default TicketsPerYear;
